@import '../../App.scss';

header {
  section{
    width: 90%;
    margin: 0 auto;

    &:first-child {
      div {
        display: flex;
        justify-content: flex-end;

        a {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.75rem 0.25rem;
          color: white;
          background-color: $text-color;

          .icon {
            width: 16px;
          }
        }
      }
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 20%;

        img {
          width: 100%;
        }
      }

      nav {
        width: 50%;
        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          list-style: none;

          li {
            margin-left: 1rem;
            a {
              color: $text-color;
            }
          }
        }
      }
    }
  }
}