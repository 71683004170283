@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@200..700&display=swap');
@import url("https://use.typekit.net/ofx4uzr.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$primary-color: #E3216E;
$secondary-color: #F18820;
$text-color: #414C50;
$accent-color: #C6C6C5;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: $text-color;

  h1{
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    font-size: 2.8rem;
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
  }
  h3 {
    font-size: 1.25rem;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: $text-color;
  }
  
}


