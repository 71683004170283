@import '../App.scss';

.formContainer {
  width: 90%;
  margin: 2rem auto;

  .infos {
    margin: 1rem 0;
    position: sticky;
    top: 1rem;
    z-index: 99;

    .progressBar {
      height: 10px;
      background: linear-gradient(90deg, $primary-color, $secondary-color);
    }
    .progressPercentage {
      display: flex;
      align-items: center;
      
      .estimatedPrice {
        font-weight: 600;
      }
    }
  }

  .formStep {
    text-align: center;
    span {
      font-style: italic;
      font-size: 0.8rem;
    }
    .formStep-element {
      width: 80%;
      margin: 2rem auto 4rem;

      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;
  
        label {
          position: relative;
          width: 25%;
          aspect-ratio: 1 / 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          background-color: $text-color;
          color: white;
          text-align: center;
          z-index: 1;
          cursor: pointer;


          input {
            appearance: none;

            &[type="radio"] {
              &:focus, 
              &:checked {
                &:before {
                  content: '';
                  position: absolute;
                  top: -3px;
                  left: -3px;
                  right: -3px;
                  bottom: -3px;
                  background: linear-gradient(45deg, $primary-color, $secondary-color);
                  background-size: 300% 300%;
                  z-index: -1;
                  transition: all 0.4s ease-in-out;
                  animation: gradient-border-animation 6s ease infinite;
                }
              
                &:hover:before {
                  animation: gradient-border-animation-hover 2s linear infinite;
                }
              }
            }
            &[type="checkbox"] {
              &:checked {
                &:before {
                  content: '';
                  position: absolute;
                  top: -3px;
                  left: -3px;
                  right: -3px;
                  bottom: -3px;
                  background: linear-gradient(45deg, $primary-color, $secondary-color);
                  background-size: 300% 300%;
                  z-index: -1;
                  transition: all 0.4s ease-in-out;
                  animation: gradient-border-animation 6s ease infinite;
                }
              
                &:hover:before {
                  animation: gradient-border-animation-hover 2s linear infinite;
                }
              }
            }
          }
        }
        &.two {
          label {
            width: 20%;
            font-size: 0.75rem;
          }
        }
        &.four {
          label {
            width: 20%;
            font-size: 0.75rem;
          }
        }
      }

      .full {
        width: 100%;
        input[type="text"] {
          width: 100%;
          padding: 0.5rem;
          font-size: 1rem;
          border: 2px solid $text-color; 
          outline: none;
          transition: border-color 0.4s ease, box-shadow 0.4s ease;
          color: $text-color;

          &:focus {
            border-color: $primary-color;
            box-shadow: 0 0 10px 2px rgba($primary-color, 0.5); 
          }

          &:hover {
            border-color: $secondary-color;
          }

          &::placeholder {
            color: lighten($text-color, 20%);
            opacity: 0.7;
          }
        }
      }
      .demi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        label {
          all: inherit;
          width: 40%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: wrap;

          input {
            width: 100%;
            padding: 0.5rem;
            border: 2px solid $text-color; 
            outline: none;
            transition: border-color 0.4s ease, box-shadow 0.4s ease;
            color: $text-color;
            font-size: 1rem;
  
            &:focus {
              border-color: $primary-color;
              box-shadow: 0 0 10px 2px rgba($primary-color, 0.5); 
            }
  
            &:hover {
              border-color: $secondary-color;
            }
  
            &::placeholder {
              color: lighten($text-color, 20%);
              opacity: 0.7;
            }
          }
        }
      }

      @media screen and (min-width:800px) {
        width: 50%;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      button {
        padding: 1rem 2rem;
        border: 3px solid transparent;
        border-image: linear-gradient(90deg, $primary-color 0%, $secondary-color 50%);
        border-image-slice: 1;
        background-color: transparent;      
        background-image: initial;  
        z-index: 1;
        cursor: pointer;

        &.back {
          border: 3px solid $text-color;
        }
      }
    }
  }
}

@keyframes gradient-border-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-border-animation-hover {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}