@import '../../App.scss';

footer {
  margin-top: 5rem;

  section {
    &:first-child {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      border-top: 1px solid $secondary-color;
      padding: 1rem 3rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 200;

      article {
        flex: 33%;
        padding-right: 4rem;

        h2 {
          margin-bottom: 1rem;
          text-transform: uppercase;
          font-weight: 300;
          text-align: left;
          font-family: "Montserrat", sans-serif;
          font-weight: 200;
        }

        ul {
          list-style: none;

          li {
            a {
              color: $text-color;
              font-family: "Montserrat", sans-serif;
              font-weight: 200;
            }
          }
        }

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;

          a {
            width: 48px;
            aspect-ratio: 1 / 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            background-color: $text-color;

            .icon {
              font-size: 24px;
              color: white;
            }
          }
        }

        &:first-child {
          width: 45%;
        }

        &:nth-of-type(2) {
          ul {
            li {
              margin-bottom: 1rem;
            }
          }
        }

        &:last-child {
          ul {
            li {
              margin-bottom: 1rem;
              
              a {
                font-family: "Montserrat", sans-serif;
                font-weight: 200;
              }
            }
          }
        }
      }
    }

    &:last-child {
      border-top: 3px solid $secondary-color;
      padding: 1rem 0;
      text-align: center;
    }
  }
}